var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{staticClass:"text-center text-primary d-flex align-items-center",attrs:{"title":_vm.editable
      ? 'Editar categorias'
      : _vm.modalDepreciableAssetsForm
      ? 'Cadastrar item'
      : 'Excluir item',"no-close-on-backdrop":"","hide-footer":"","hide-header-close":""},model:{value:(_vm.modalDepreciableAssets),callback:function ($$v) {_vm.modalDepreciableAssets=$$v},expression:"modalDepreciableAssets"}},[_c('validation-observer',{ref:"ModalDepreciableAssets"},[(_vm.modalDepreciableAssetsForm)?_c('b-form',[_c('b-row',{staticClass:"my-1 m-2 text-left mt-2 d-flex justify-content-center flex-column"},[_c('b-row',{staticClass:"d-flex justify-content-sm-around pb-1"},[_c('b-col',[_c('b-form-group',{staticClass:"mb-2"},[_c('label',{staticClass:"font-weight-bold text-primary",attrs:{"for":"title"}},[_vm._v("Nome :")]),_c('validation-provider',{attrs:{"custom-messages":{ required: 'Este campo é obrigatório' },"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","placeholder":"Digite o nome do tipo"},model:{value:(_vm.modelDepreciableAssets.name),callback:function ($$v) {_vm.$set(_vm.modelDepreciableAssets, "name", $$v)},expression:"modelDepreciableAssets.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3566937891)})],1)],1)],1),_c('b-row',{staticClass:"my-0 m-0 mb-0 d-flex justify-content-md-start text-start"},[_c('b-col',{staticClass:"justify-content-center d-flex",attrs:{"col":"","xs":"12","sm":"12","md":"6","lg":"6"}})],1)],1),_c('hr',{staticClass:"invoice-spacing mb-3"}),_c('b-row',{staticClass:"justify-content-between d-flex p-2"},[_c('b-button',{staticClass:"mr-2",attrs:{"disabled":_vm.loading,"variant":"warning"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v("Voltar ")]),(_vm.editable ? _vm.modelDepreciableAssets : _vm.modalInactivate.disabled)?_c('b-button',{staticClass:"d-flex justify-content-center mr-2",attrs:{"variant":_vm.modelDepreciableAssets.deleted_at ? 'success' : 'danger',"disabled":!_vm.modelDepreciableAssets.name || _vm.loading},on:{"click":function($event){_vm.modalInactivate = true}}},[(!_vm.loading)?_c('span',{attrs:{"id":"modal-inactivate"},on:{"click":function($event){_vm.modelDepreciableAssets.deleted_at
                ? _vm.updateProjectType
                : (_vm.modalInactivate = true)}}},[_vm._v(_vm._s(_vm.modelDepreciableAssets.deleted_at ? 'Reativar' : 'Inativar'))]):_vm._e(),(_vm.loading)?_c('b-spinner'):_vm._e()],1):_vm._e(),_c('div',{staticClass:"d-flex justify-content-between"},[(_vm.editable && !_vm.modelDepreciableAssets.deleted_at)?_c('b-button',{attrs:{"variant":"primary","disabled":!_vm.modelDepreciableAssets.name ||
              _vm.loading},on:{"click":_vm.updateProjectType}},[(!_vm.loading)?_c('span',[_vm._v(" Salvar Alterações ")]):_vm._e(),(_vm.loading)?_c('b-spinner',{staticClass:"spinner"}):_vm._e()],1):_vm._e(),(!_vm.editable)?_c('b-button',{attrs:{"variant":"primary","disabled":!_vm.modelDepreciableAssets.name ||
              _vm.loading},on:{"click":_vm.saveProjectType}},[(!_vm.loading)?_c('span',[_vm._v("Salvar")]):_vm._e(),(_vm.loading)?_c('b-spinner'):_vm._e()],1):_vm._e()],1),_c('b-modal',{attrs:{"title":"Inativar item","no-close-on-backdrop":"","hide-footer":"","hide-header-close":""},model:{value:(_vm.modalInactivate),callback:function ($$v) {_vm.modalInactivate=$$v},expression:"modalInactivate"}},[_c('b-row',{staticClass:"mt-1 justify-content-center d-flex text-center"},[_c('p',[_vm._v(" Deseja realmente "),_c('strong',[_vm._v(_vm._s(_vm.modelDepreciableAssets.deleted_at ? 'reativar' : 'inativar'))]),_vm._v(" este item "),_c('strong',[_vm._v(_vm._s(_vm.modelDepreciableAssets.name))]),_vm._v("? ")])]),_c('hr',{staticClass:"invoice-spacing mb-3"}),_c('b-row',{staticClass:"justify-content-between d-flex mt-3 p-1"},[_c('b-button',{attrs:{"variant":"warning"},on:{"click":function($event){_vm.modalInactivate = false}}},[_vm._v(" Cancelar ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){_vm.modelDepreciableAssets.deleted_at
                  ? _vm.activeProjectType()
                  : _vm.removeProjectType()}}},[_vm._v(" Confirmar ")])],1)],1)],1)],1):_c('div',{attrs:{"modalOpenDelete":""}},[_c('div',[_c('b-row',{staticClass:"mt-1 justify-content-center d-flex text-center"},[_c('p',[_vm._v(" Deseja realmente "),_c('strong',[_vm._v(_vm._s(_vm.modelDepreciableAssets && 'Excluir este item '))]),_c('strong',[_vm._v(_vm._s(_vm.modelDepreciableAssets.name))]),_vm._v("? ")])]),_c('hr',{staticClass:"invoice-spacing mb-3"}),_c('b-row',{staticClass:"justify-content-between d-flex mt-3 p-1"},[_c('b-button',{attrs:{"variant":"warning"},on:{"click":function($event){return _vm.$emit('closeModal')}}},[_vm._v(" Cancelar ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.removeProjectType()}}},[_vm._v(" Confirmar ")])],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }