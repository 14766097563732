<template>
  <div>
    <b-card title="Ações">
      <b-row class="my-1 text-right mt-2 d-flex align-items-center">
        <b-col sm="12" md="6" class="d-flex">
          <b-form-group class="flex-grow-1">
            <b-form-input type="text" v-model="search" placeholder="Buscar item"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" class="d-flex justify-content-end">
          <b-button class="mb-1" variant="primary" @click="openModalDefault" block>Novo tipo +</b-button>
        </b-col>
      </b-row>
      <!-- <b-row>
        <b-col>
          <b-form-group>
            <label for="category" class="font-weight-bold text-primary">Status:</label>
            <b-form-select
              v-model="statusOptionSelected"
              :options="statusOptions"
              value-field="value"
              text-field="name"
              placeholder="Selecione um status"
              @change="handleFilterChange"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row> -->
    </b-card>
    <b-card>
      <b-row class="justify-content-start d-flex">
        <b-tabs content-class="mt-3">
          <b-tab title="Ativos" @click="(ProjectTypeList = false), getAllProjectType()" active> </b-tab>
          <b-tab title="Inativos" @click="(ProjectTypeList = true), getAllProjectType()"> </b-tab>
        </b-tabs>
      </b-row>

      <b-table
        responsive="sm"
        small
        :items="items"
        :fields="fields"
        :total-rows="totalRows"
        :per-page="perPage"
        show-empty
        class="p-1 w-100"
      >
        <template #empty>
          <b-alert show variant="primary">
            <p class="text-center">
              <br />
              <strong>Não há dados para exibir</strong>
            </p>
            <hr />
          </b-alert>
        </template>
        <template v-slot:cell(actions)="{ item }">
          <ActionsProjectType @openModal="openEditModal(item)" @openModaDelete="openDeleteModal(item)" :item="item" />
        </template>
        <template v-slot:cell(project_cost)="{ item }">
          {{ item.project_cost == true ? 'Sim' : 'Não' }}
        </template>

        <template v-slot:cell(office_cost)="{ item }">
          {{ item.office_cost == true ? 'Sim' : 'Não' }}
        </template>

        <template v-slot:cell(other_cost)="{ item }">
          {{ item.other_cost == true ? 'Sim' : 'Não' }}
        </template>
      </b-table>
      <ModalProjectType
        :modalDepreciableAssets="modalDepreciableAssets"
        :modalDepreciableAssetsForm="modalDepreciableAssetsForm"
        :editable="editable"
        :itemUpdate="itemUpdate"
        @updateList="handleUpdateList"
        @renderList="getAllProjectType"
        @closeModal="closeModal()"
      />
      <b-pagination
        class="justify-content-center"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        @change="getPage"
        aria-controls="my-table"
      ></b-pagination>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTabs,
  BTab,
  BRow,
  BButton,
  BTable,
  BFormGroup,
 //BFormSelect,
  BFormInput,
  BCol,
  // BIconTrash,
  BPagination,
  BAlert
} from 'bootstrap-vue';
import { VBTooltip } from 'bootstrap-vue';
import ActionsProjectType from './components/ActionsProjectType.vue';
import ModalProjectType from './components/ModalProjectType.vue';
export default {
  directives: {
    'b-tooltip': VBTooltip
  },
  components: {
    BCard,
    BTabs,
    BTab,
    BTable,
    BCol,
    BRow,
    BButton,
    BFormGroup,
    BFormInput,
    //BFormSelect,
    // BIconTrash,
    ModalProjectType,
    ActionsProjectType,
    BPagination,
    BAlert
  },
  data: () => ({
    modalDepreciableAssets: false,
    modalDepreciableAssetsForm: false,
    editable: false,
    itemUpdate: null,
    perPage: 20,
    currentPage: 1,
    totalRows: 0,
    filterTrigged: false,
    statusOptions: [
      {
        value: 'todos',
        name: 'Todos'
      },
      {
        value: 'office',
        name: 'Escritório'
      },
      {
        value: 'project',
        name: 'Projeto'
      },
      {
        value: 'other_cost',
        name: 'Outros custos'
      }
    ],
    statusOptionSelected: 'todos',
    fields: [
      {
        key: 'actions',
        label: 'Ações',
        sortable: false
      },
      {
        key: 'name',
        label: 'item',
        sortable: true
      },
      {
        key: 'slug',
        label: 'Cod. relatorio',
        sortable: true
      },
    ],
    items: [],
    ProjectTypeList: false,
    search: ''
  }),

  created() {
    this.getAllProjectType();
  },

  computed: {
    currentWorkspace() {
      return this.$store.getters.currentWorkspace;
    },
    depreciableAssetsFilteres() {
      let values = [];
      values = this.items.filter(item => {
        return item.expense_category_name.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      });
      return values;
    },
    depreciableAssetsWithDepreciation() {
      return this.depreciableAssetsFilteres.map(item => {
        const acquisitionValue = item.acquisition_value;
        const residualValue = item.residual_value;
        const lifespanMonths = item.lifespan_months;

        // Calcular a depreciação mensal fixa em valor monetário
        const monthlyDepreciation = lifespanMonths > 0 ? -(acquisitionValue - residualValue) / lifespanMonths : 0;

        return {
          ...item,
          depreciable: ` ${monthlyDepreciation.toFixed(2)}` // Ajuste a precisão e o formato conforme necessário
        };
      });
    }
  },

  methods: {
    async getPage(val) {
      this.currentPage = val;
      console.log('this.filterTrigged',this.filterTrigged)
      if (this.filterTrigged) {
        await this.getFilter();
      } else {
        await this.getAllProjectType();
      }
    },
    getAllProjectType() {
      this.items = [];
      let body = {
        inactive: false,
        workspace_id: this.$store.getters.currentWorkspace.id,
        page: this.currentPage,
        perPage: this.perPage
      };
      if (!this.ProjectTypeList) {
        this.$store
          .dispatch('getAllProjectType', body)
          .then(resp => {
            if (resp) {
              this.items = resp.data;
              this.totalRows = resp.pagination.total;
            }
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        this.getInactivesDepreciableAssets();
      }
    },
    async getFilter() {
      this.items = [];
      let body = {
        inactive: false,
        workspace_id: this.$store.getters.currentWorkspace.id,
        page: this.currentPage,
        perPage: this.perPage,
        cost_category: this.statusOptionSelected
      };
      if (!this.ProjectTypeList) {
        this.$store
          .dispatch('getAllProjectType', body)
          .then(resp => {
            if (resp) {
              this.items = resp.data;
              this.totalRows = resp.pagination.total;
            }
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        this.getInactivesDepreciableAssets();
      }
    },

    getInactivesDepreciableAssets() {
     
      let body = {
        inactive: true,
        workspace_id: this.$store.getters.currentWorkspace.id,
        page: this.currentPage,
        perPage: this.perPage,
        cost_category: this.statusOptionSelected
      };
      this.$store
        .dispatch('getAllProjectType', body)
        .then(resp => {
          if (resp) {
            this.items = resp.data;
            this.totalRows = resp.pagination.total;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    cleanFilter() {
      this.search = '';
    },
    openEditModal(item) {
      this.modalDepreciableAssets = true;
      this.editable = true;
      this.modalOpenDelete = false;
      this.modalDepreciableAssetsForm = true;
      this.itemUpdate = item.id;
    },
    openDeleteModal(item) {
      this.modalDepreciableAssets = true;
      this.modalDepreciableAssetsForm = false;
      this.modalOpenDelete = true;
      this.itemUpdate = item.id;
    },
    openModalDefault() {
      this.modalDepreciableAssets = true;
      this.modalDepreciableAssetsForm = true;
      this.modalOpenDelete = false;
      this.editable = false;
      // this.itemUpdate = item.id
    },
    closeModal() {
      this.modalDepreciableAssets = false;
      this.editable = false;
    },
    handleUpdateList() {
      this.getAllProjectType();
    },
    handleFilterChange() {
      this.getFilter();
      this.filterTrigged = true;
    }
  }
};
</script>
